export default {
    en: [
        {num: 1, label: 'Order'},
        {num: 3, label: 'Material'},
        {num: 5, label: 'Employee payment'},
        {num: 7, label: 'Other'},
    ],
    et: [
        {num: 1, label: 'Tellimus'},
        {num: 3, label: 'Materjal'},
        {num: 5, label: 'Töötajale maksmine'},
        {num: 7, label: 'Muu'},
    ],
    ru: [
        {num: 1, label: 'Заказ'},
        {num: 3, label: 'Материал'},
        {num: 5, label: 'Оплата сотруднику'},
        {num: 7, label: 'Другое'},
    ]
}