<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4" v-if="cashboxes?.length">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Cashbox transactions') }}</div>
<!--            <AddNewButton :disabled="!cashboxes?.length" :label="$t('Add')" @click="addNewItem" />-->
            <AddNewButton :label="$t('Add')" @click="addNewItem" />
          </template>
          <template #end>
<!--            <div class="p-input-icon-left">-->
<!--              <i class="pi pi-search" />-->
<!--              <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--            </div>-->
            <CustomDropdown id="cashbox_id"
                            class="p-mr-2"
                            :disabled="!canChangeCashBoxes || dataTableIsLoading"
                            style="min-width: 180px"
                            @change="changeCashbox($event.value.id)"
                            v-model="selectedCashbox"
                            :options="cashboxes"
                            :preventFilter="true"
                            :filter="false"
                            :showClear="false">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <span>{{ slotProps.value.name }}</span>
                </div>
                <span v-else>Select cashbox</span>
              </template>
              <template #option="slotProps">
                <div>
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </CustomDropdown>
            <DatesRangeCalendar :minDate="computedMinDate" :disabled="dataTableIsLoading" @change-dates-range="changeDatesRange" class="p-mr-2"/>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" :clearLastSearchingValue="clearLastSearchingValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <div class="p-d-flex p-jc-start p-flex-wrap p-mb-4 p-mt-0" v-if="canChangeCashBoxes">
          <div @click="changeCashbox(cashbox.id)"
               class="cashbox-block p-m-1"
               :class="{'transparented-primary-color': cashbox.id === cashboxId}"
               v-for="(cashbox, index) of cashboxes"
               :key="index">{{ cashbox.name }}: <b>{{ formatCurrency(cashbox.total) }}</b>
          </div>
        </div>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else-if="!isLoading"
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Cashbox transactions')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'type', title: 'Type', sortable: false},
              {name: 'order_id', title: 'Order', sortable: false},
              {name: 'invoice_id', title: 'Invoice', sortable: false},
              {name: 'date', title: 'Date', sortable: false},
              {name: 'cashbox_id', title: 'Cashbox', sortable: false},
              {name: 'description', title: 'Description', sortable: false},
              {name: 'sum', title: 'Sum', sortable: false},
              {name: 'total', title: 'Total', sortable: false},
              {name: 'created_at', title: $t('Created'), sortable: false},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <span v-if="CashboxTransactionTypes[$i18n.locale].filter(state => state.num === item.type) && CashboxTransactionTypes[$i18n.locale].filter(state => state.num === item.type).length">{{ CashboxTransactionTypes[$i18n.locale].filter(state => state.num === item.type)[0].label }}</span>
              </td>
              <td>
                <router-link v-if="item.order" :to="{ path: `/orders/${item.order.number}` }" target="_blank">
                  <span>{{ item.order.number }}</span>
                </router-link>
                <span v-else>-</span>
              </td>
              <td>
                <router-link v-if="item.sales_invoice" :to="{ path: '/sales-invoices', query: { search: item.sales_invoice.number } }" target="_blank">
                  <span>{{ item.sales_invoice.number }}</span>
                </router-link>
                <span v-else>-</span>
              </td>
              <td>
                {{ formatDate(item.date) }}
              </td>
              <td>
                <span v-if="item.cashbox">{{ item.cashbox.name }}</span>
              </td>
              <td>
<!--                <div v-if="item.order">{{ item.order.name }}<span v-if="item.car">, {{ item.car.make?.name || '' }} {{ item.car.model?.name || '' }}, {{ item.car.plate_number || '' }}</span></div>-->
<!--                <div v-else>{{ item.description || '' }}</div>-->
                <div>{{ item.description }}</div>
                <div class="table-date-line"><span v-if="item.deleted_at">Deleted: {{ formatDate(item.deleted_at) }}</span><span v-if="item.deletedBy">, {{ item.deletedBy.first_name }} {{ item.deletedBy.last_name }}</span></div>
              </td>
              <td>
                <span :class="item.sum >= 0 ? 'green-color' : 'warning-color'"><span v-if="item.sum >= 0">+</span>{{ formatCurrency(item.sum) }}</span>
<!--                <span v-else-if="item.sum && item.sum < 0" class="warning-color">{{ formatCurrency(item.sum) }}</span>-->
              </td>
              <td>
                <span>{{ formatCurrency(item.total) }}</span>
<!--                <i v-else class="ti-minus"></i>-->
              </td>
<!--              <td>-->
<!--                <span>{{ formatDate(item.date) }}</span>-->
<!--              </td>-->
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) }},
                </span>
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
<!--                  <span v-if="item.creator.role === 8 && item.creator.company_name">({{ item.creator.company_name }})</span>-->
                </span>
              </td>
              <td v-if="!!actionButtonsCount">
                <div class="p-d-flex">
                  <EditButton :disabled="($store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant) || item.type === 1 || item.deleted_at || dataTableIsLoading"
                              @click="editItem(item)" />
                  <DeleteButton @click="confirmItemDelete(item)"
                                :disabled="item.type === 1 || item.deleted_at || dataTableIsLoading"
                                v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="item && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>


        <CashboxTransactionModal :visible="itemModal"
                                 :item="item"
                                 :selectedCashboxId="selectedCashbox?.id"
                                 :cashboxes="cashboxes"
                                 :employees="employees"
                                 @update-items="updateItems"
                                 @close="closeItemModal">
        </CashboxTransactionModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import CashboxTransactionTypes from '@/translations/CashboxTransactionTypes'
import CashboxTransactionModal from '@/pages/finance/components/CashboxTransactionModal'
import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'
import datatableMixins from "@/mixins/datatableMixins";
import httpMixins from "@/mixins/httpMixins";
import DatesRangeCalendar from "@/components/DatesRangeCalendar";
import settings from "@/settings";
import permissionsMixins from "@/mixins/permissionsMixins";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins, permissionsMixins ],
  components: { CashboxTransactionModal, DatesRangeCalendar },
	data() {
		return {
      cashboxes: [],
      selectedCashbox: null,
      cashboxId: null,
      isLoading: true,
      fieldsExpand: 'creator,cashbox,order,sales_invoice,deletedBy,car,employee',
      apiLink: `cashbox-transaction/get-transactions`,
      routePath: '/cashbox-transactions',
      // searchData: '',
      // sort: {
      //   column: 'id',
      //   direction: '-',
      // },
      // defaultSort: {
      //   column: 'created_at',
      //   direction: '-',
      // },
			// items: [],
      // itemModal: false,
      // itemDeleteModal: false,
      // confirmItemDeleteModal: false,
			// item: {},
      // updatedItem: null,
      // itemToDelete: {},
			// submitted: false,
      // pagination: {
      //   totalCount: null,
      //   pageCount: null,
      //   currentPage: 1,
      //   perPage: 25
      // },
      CashboxTransactionTypes,
      // constants
		}
	},
  // watch: {
    // '$i18n.locale'() {
    //   if (this.cashboxes?.length) {
    //     let cashbox = this.cashboxes.filter(cashbox => cashbox.id === 0)
    //     if (cashbox && cashbox.length) {
    //       cashbox[0].name = this.$t('All cashboxes')
    //     }
    //   }
    // },
    // '$route'() {
    //   this.checkRouteQuery()
    //   if (this.$route.path === '/login' || this.$route.path !== '/cashbox-transactions') {
    //     return false
    //   }
    //   this.getItems()
    // },
    // '$store.state.firstLayerIsOpened'() {
    //   if (!this.$store.state.firstLayerIsOpened) {
    //     if (this.itemModal) {
    //       this.closeItemModal()
    //     }
    //     if (this.confirmItemDeleteModal) {
    //       this.closeConfirmItemDeleteModal()
    //     }
    //   }
    // },
    // '$store.state.user.emailAccounts'() {
    //   if (this.$store.state.user.emailAccounts.length) {
    //     // this.emailAccounts = this.$store.state.user.emailAccounts
    //     console.log(this.$store.state.user.emailAccounts)
    //   }
    // }
  // },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    // this.sort.column = this.defaultSort.column
    // this.sort.direction = this.defaultSort.direction

    this.generateThisMonthRange()
    this.checkRouteQuery()
    // this.getUserCashboxes(true)
    this.getEmployees()
    // this.getUserCashboxes().then(() => this.getItems(false))
    this.getCashoxesAndItems()
	},
  // unmounted() {
  //   this.items = []
  // },
  methods: {
    getCashoxesAndItems() {
      this.getUserCashboxes().then(() => {
        if (this.cashboxId) {
          this.getItems()
        } else {
          this.isLoading = false
        }
      })
    },
    changeDatesRange({ fromTimestamp, toTimestamp }, loadData = false) {
      this.fromTimestamp = fromTimestamp
      this.toTimestamp = toTimestamp
      this.resetCurrentPageData()
      if (loadData) {
        // console.log('load data')
        this.getItems()
      }
    },

    async getUserCashboxes() {
      let newCashboxesTemplate = []
      try {
        const { status, data } = await httpClient('cashbox/get-user-cashboxes')
        if (status === 200 && data) {
          // const cashboxes = data.map(cashbox => cashbox.cashbox)
          const cashboxes = data
          this.$store.commit('updateUserCashboxes', data)

          // if (cashboxes.length > 1) {
          //   let total = cashboxes.filter(cashbox => cashbox.id !== 0).reduce((sum, item) => sum + +item.total, 0)
          //   newCashboxesTemplate.push({ id: 0, name: this.$t('All cashboxes'), total })
          //   cashboxes.forEach(cashbox => newCashboxesTemplate.push(cashbox))
          //   if (!this.selectedCashbox) {
          //     this.selectedCashbox = newCashboxesTemplate[0]
          //     this.cashboxId = this.selectedCashbox.id
          //   } else {
          //     this.selectedCashbox = newCashboxesTemplate.filter(i => i.id === this.selectedCashbox.id)[0]
          //   }
          // } else if (cashboxes.length === 1) {
          //   newCashboxesTemplate.push(cashboxes[0])
          //   if (!this.selectedCashbox) {
          //     this.selectedCashbox = newCashboxesTemplate[0]
          //     this.cashboxId = this.selectedCashbox.id
          //   } else {
          //     this.selectedCashbox = newCashboxesTemplate.filter(i => i.id === this.selectedCashbox.id)[0]
          //   }
          // }

          if (cashboxes?.length) {
            cashboxes.forEach(cashbox => newCashboxesTemplate.push(cashbox))
            if (!this.selectedCashbox) {
              this.selectedCashbox = newCashboxesTemplate[0]
              this.cashboxId = this.selectedCashbox.id
            } else {
              this.selectedCashbox = newCashboxesTemplate.filter(i => i.id === this.selectedCashbox.id)[0]
            }
          }
        }
        this.cashboxes = newCashboxesTemplate
        // if (getItems) {
        //   this.getItems()
        // }
      } catch(err) {
        this.showError(err)
      }
    },
    changeCashbox(id) {
      if (id === this.cashboxId) return false
      if (id || id === 0) {
        this.selectedCashbox = this.cashboxes.find(cashbox => cashbox.id === id)
        this.cashboxId = this.selectedCashbox.id
      } else {
        this.cashboxId = this.selectedCashbox.id
      }

      this.searchData = null
      this.clearLastSearching()
      this.resetRouteAndPagination()

      this.getItems(true)
    },
    // searchItems(searchData) {
    //   this.searchData = searchData
    //   this.$router.push({
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       search: this.searchData,
    //     }
    //   })
    // },
    updateItems(itemId, clearItem = false) {
      if (itemId) {
        this.updatedItemId = itemId
      }
      if (clearItem) {
        this.item = {}
        this.submitted = false
      }
      // this.getUserCashboxes().then(() => this.getItems(false))
      // this.getUserCashboxes(true)

      this.getCashoxesAndItems()
      // this.getItems(false)
    },
    // checkRouteQuery() {
    //   if (this.$route.query.page) {
    //     this.pagination.currentPage = +this.$route.query.page
    //   } else {
    //     this.pagination.currentPage = 1
    //   }
    //   if (this.$route.query.perPage) {
    //     this.pagination.perPage = +this.$route.query.perPage
    //   } else {
    //     this.pagination.perPage = 25
    //   }
    //   if (this.$route.query.sort) {
    //     const firstSign = this.$route.query.sort[0]
    //     if (firstSign === '-') {
    //       this.sort.direction = '-'
    //       let sortColumn = this.$route.query.sort.split('')
    //       this.sort.column = sortColumn.splice(1).join('')
    //     } else {
    //       this.sort.direction = ''
    //       this.sort.column = this.$route.query.sort
    //     }
    //   }
    //
    //   if (this.$route.query.search) {
    //     this.searchData = this.$route.query.search
    //   } else {
    //     this.searchData = ''
    //   }
    // },
    // sortItems(columnName) {
    //   if (this.sort.column !== columnName) {
    //     this.sort.direction = ''
    //   } else {
    //     this.sort.direction = this.sort.direction === '' ? '-' : '';
    //   }
    //   this.sort.column = columnName
    //
    //   this.$router.push({
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       sort: this.sort.direction + this.sort.column
    //     }
    //   })
    // },
    // isItemUpdated(itemId) {
    //   return this.updatedItem === itemId
    // },
    // checkAllItemsAreSelected() {
    //   return this.selectedItems.length === this.items.length
    // },
    async getItems(showSpinner = false) {
      // console.log('get items')
      // if (!this.cashboxId) return false
      this.dataTableIsLoading = true
      if (showSpinner) {
        this.isLoading = true
      }
      // const ids = []
      // this.cashboxes.filter(box => box.id !== 0)?.forEach(cashbox => ids.push(cashbox.id))
      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}&cashbox=${this.cashboxId}`, { ids })
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { cashbox_id: this.cashboxId })

        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: {
            cashbox_id: this.cashboxId,
            // userId: this.$route.params.id,
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data

          // let i = 0
          // let total
          // this.items = data.map(item => {
          //   if (i === 0) {
          //     total = this.selectedCashbox.total
          //     // total = this.items[0]?.total
          //   } else if (i > 0) {
          //     total = total - data[i - 1].sum
          //   }
          //   i++
          //   return {
          //     ...item,
          //     total
          //   }
          // })

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
      }
    },
    // closeItemModal() {
    //   this.itemModal = false
    //   if (this.$store.state.firstLayerIsOpened === true ) {
    //     this.$store.commit('toggleFirstLayer', false)
    //   }
    //   this.item = {}
    //   this.submitted = false
    // },
    // addNewItem() {
		// 	this.item = {}
		// 	this.submitted = false
		// 	this.itemModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
		// editItem(item) {
    //   if (item.payment_id) {
    //     return false
    //   }
		// 	this.item = item
    //
    //   this.itemModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
    // confirmItemDelete(itemToDelete) {
    //   this.itemToDelete = itemToDelete
    //   this.confirmItemDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmItemDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmItemDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    async deleteItem() {
      if (this.itemToDelete.payment_id || this.itemToDelete.sales_invoice_id || this.itemToDelete.deleted_at) {
        return false
      } else {
        try {
          const { status } = await httpClient.post('cashbox-transaction/delete-transaction', { id: this.itemToDelete.id })
          if (status === 200) {
            this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: 1750})
            this.updateItems(this.itemToDelete.id)
            this.closeConfirmItemDeleteModal()
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
	},
  computed: {
    computedMinDate() {
      if (this.userIsAdminOrAccountant) {
        return null
      } else {
        let d = new Date()
        d.setMonth(d.getMonth() - 1)
        return d
      }
    },
    canChangeCashBoxes() {
      return (this.cashboxes?.length > 1)
    },
    // itemsArrayIsEmpty() {
    //     return !(this.items && this.items.length)
    // },
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin || this.$store.state.user.role === constants.userRoles.accountant) {
        return 2
      } else {
        return 0
      }
    },
    // itemToDeleteData() {
    //   return this.itemToDelete.description
    // },
  }
}
</script>

<style scoped lang="scss">
</style>